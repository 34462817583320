import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { setSuccessMessage, displayErrorMessages } from '../../utils/messages';
import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import SaleService from '../../services/sales/sale.service';


const DeleteSaleForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [sale, setSale] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const loadSale = async () => {
        SaleService.getSale(id)
        .then(response => {
            setSale(response.data)
        })
    }

    const handleSubmit = e => {
        e.preventDefault();

        SaleService.deleteSale(id)
        .then(response => {
            setSuccessMessage('Venta eliminada exitosamente')
            navigate('/sales')      
        })
        .catch(error => displayErrorMessages(error.response.data))
    }

    useEffect(() => {
        loadSale();
    }, [])

    return <Layout title='Eliminar venta'>
        <CardMenu>
            <div role="alert" className="fade bg-white alert alert-danger show">
                <h4 className="alert-heading">¿Deseas eliminar la venta {sale.invoice}?</h4>
                <p>Esta acción no se puede deshacer</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/sales')}>Cancelar</button>
                        <button type='submit' className='btn btn-danger'>Eliminar</button>
                    </div>
                </div>
            </form>            
        </CardMenu>
    </Layout>
}

export default DeleteSaleForm