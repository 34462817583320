export const menuConfig = {
    catalogs: {
        title: 'Catálogos',
        submenus: [
            {
                title: 'Clientes',
                link: '/customers',
                permission: 'customers.cus_view_customer'
            },
            {
                title: 'Departamentos',
                link: '/departments',
                permission: 'departments.cus_view_department'
            },
            {
                title: 'Monedas',
                link: '/currencies',
                permission: 'sales.cus_view_currency'
            },
            {
                title: 'Países',
                link: '/countries',
                permission: 'countries.cus_view_country'
            },
            {
                title: 'Roles',
                link: '/roles',
                permission: 'users.cus_view_role'
            },
            {
                title: 'Términos de pago',
                link: '/payment-terms',
                permission: 'sales.cus_view_payment_term'
            },
            {
                title: 'Usuarios',
                link: '/users',
                permission: 'users.cus_view_user'
            },
            {
                title: 'Vendedores',
                link: '/sellers',
                permission: 'sales.cus_view_seller'
            }
        ]
    },
    sales: {
        title: 'Ventas',
        submenus: [
            {
                title: 'Informe de ventas',
                link: '/sales',
                permission: 'sales.cus_view_sale'
            }
        ]
    },
    reports: {
        title: 'Reportes',
        submenus: [
            {
                title: 'Estados de cuenta',
                link: '/reports/sales/account-status-report',
                permission: 'sales.cus_view_account_status_report'
            },
            {
                title: 'Ventas por cliente',
                link: '/reports/sales/customer-sales-report',
                permission: 'sales.cus_view_customer_sales_report'
            },
            {
                title: 'Ventas por ejecutivo',
                link: '/reports/sales/executive-sales-report',
                permission: 'sales.cus_view_executive_sales_report'
            }
        ]
    }
}