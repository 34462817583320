import api from '../api'

class PaymentTermService {
    getPaymentTerms(params={}){
        return api.get('/api/sales/payment-terms/', {params});
    }

    getPaymentTerm(id){
        return api.get('/api/sales/payment-terms/' + id + '/')
    }

    createPaymentTerm(payload={}){
        return api.post('/api/sales/payment-terms/', payload)
    }

    updatePaymentTerm(id, payload={}){
        return api.put('/api/sales/payment-terms/' + id + '/', payload)
    }

    deletePaymentTerm(id){
        return api.delete('/api/sales/payment-terms/' + id + '/')
    }
}

export default new PaymentTermService()