import PaymentTermForm from '../../components/payment-terms/PaymentTermForm'
import PaymentTermList from '../../components/payment-terms/PaymentTermList'
import DeletePaymentTermForm from '../../components/payment-terms/DeletePaymentTermForm'
import PaymentTermDetail from '../../components/payment-terms/PaymentTermDetail'


const paymentTermRoutes = [
    {path: '/payment-terms', permission: ['sales.cus_view_payment_term'], component: <PaymentTermList/>},
    {path: '/payment-terms/:id', permission: ['sales.cus_view_payment_term'], component: <PaymentTermDetail/>},
    {path: '/payment-terms/add', permission: ['sales.cus_add_payment_term'], component: <PaymentTermForm/>},
    {path: '/payment-terms/edit/:id', permission: ['sales.cus_change_payment_term'], component: <PaymentTermForm/>},
    {path: '/payment-terms/delete/:id', permission: ['sales.cus_delete_payment_term'], component: <DeletePaymentTermForm/>}
]

export default paymentTermRoutes