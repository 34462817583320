import api from './api';
import store from '../redux/store';
import AuthService from './auth/auth.service';
import { logout } from '../redux/actions/auth';


const setup = () => {

    api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {

        if (error.config.url !== '/api/users/login/' && error.response){
          if (error.response.status === 401){
            if (error.config.url === '/api/users/login/refresh/'){
              return store.dispatch(logout());
            }else{
              try {
                const refreshToken = await AuthService.refreshToken(localStorage.getItem('refresh'));
                localStorage.setItem('access', refreshToken.data.access);
                localStorage.setItem('refresh', refreshToken.data.refresh);  

                if(error.config.url === '/api/users/login/verify/'){
                  return true;
                }
                
                return api(error.config);
              }catch (e){
                if(error.config.url === '/api/users/login/verify/'){
                  return store.dispatch(logout());
                }
                return Promise.reject(e);
              }
            }
          }else if(error.response.status === 404){
            return window.location = '/not-found';
          }else if (error.response.status === 403){
            return window.location = '/forbidden';
          }else if (error.response.status === 500){
            return window.location = '/server-error';
          }
        }

        return Promise.reject(error);

      }
    );

  };

  export default setup;