import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages, showMessage} from '../../utils/messages';
import { hasPermissions } from '../../utils/permissions';
import SaleService from '../../services/sales/sale.service';
import UtilsService from '../../services/utils.service';


const SaleForm = () => {
    const userPermissions = useSelector(state => state.Auth.permissions);
    const canViewSalesFromOtherSellers = hasPermissions(['sales.cus_view_sales_from_other_sellers'], userPermissions)
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [sellers, setSellers] = useState([]);
    const [selectedSeller, setSelectedSeller] = useState();
    const loadSellers = async (reload=false) => {
        UtilsService.getRelatedField('sales', 'Seller')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setSellers(res);
            if(reload) showMessage('success', 'Vendedores actualizados correctamente')
        })
    }

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();
    const loadCountries = async (reload=false) => {
        UtilsService.getRelatedField('countries', 'Country')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setCountries(res);
            if(reload) showMessage('success', 'Países actualizados correctamente')
        })
    }

    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const loadCustomers = async (reload=false) => {
        UtilsService.getRelatedField('customers', 'Customer')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setCustomers(res);
            if(reload) showMessage('success', 'Clientes actualizados correctamente')
        })
    }

    const [paymentTerms, setPaymentTerms] = useState([]);
    const [selectedPaymentTerm, setSelectedPaymentTerm] = useState();
    const loadPaymentTerms = async (reload=false) => {
        UtilsService.getRelatedField('sales', 'PaymentTerm')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setPaymentTerms(res);
            if(reload) showMessage('success', 'Términos de pago actualizados correctamente')
        })
    }

    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const loadCurrrencies = async (reload=false) => {
        UtilsService.getRelatedField('sales', 'Currency')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setCurrencies(res);
            if(reload) showMessage('success', 'Monedas actualizadas correctamente')
        })
    }

    const status = [
        {value: 'Con inconvenientes', label: 'Con inconvenientes'},
        {value: 'En producción', label: 'En producción'},
        {value: 'Entregado por cobrar', label: 'Entregado por cobrar'},
        {value: 'Entregado y cobrado', label: 'Entregado y cobrado'}
    ];
    const [selectedStatus, setSelectedStatus] = useState([]);

    let formFields = {
        invoice: yup.string(),
        invoice_date: yup.string()
            .nullable(true)
            .transform(value => (value == "" ? null : value)),
        purchase_order: yup.string().required('Este campo es requerido'),
        reception_date: yup.string().required('Este campo es requerido'),
        project: yup.string().required('Este campo es requerido'),
        net_sale: yup.number()
            .positive('Este campo debe ser un número positivo')
            .transform(value => (isNaN(value) ? undefined : value))
            .required('Este campo es requerido'),
        tax: yup.number()
            .positive('Este campo debe ser un número positivo')
            .max(100, 'La comisión máxima es 100%')
            .transform(value => (isNaN(value) ? undefined : value))
            .required('Este campo es requerido'),
        comission_percentage: yup.number()
            .positive('Este campo debe ser un número positivo')
            .max(100, 'La comisión máxima es 100%')
            .transform(value => (isNaN(value) ? undefined : value))
            .required('Este campo es requerido'),
        customer: yup.string().required('Este campo es requerido'),
        country: yup.string().required('Este campo es requerido'),
        payment_term: yup.string().required('Este campo es requerido'),
        currency: yup.string().required('Este campo es requerido'),
        status: yup.string().required('Este campo es requerido')
    }

    if(canViewSalesFromOtherSellers) formFields['seller'] = yup.string().required('Este campo es requerido')

    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = (data) => {
        setSaving(true)
        if(!id){
            SaleService.createSale(data)
            .then(response => {
                setSuccessMessage('Venta creada exitosamente')
                navigate('/sales')             
            })
            .catch(error => displayErrorMessages(error.response.data))
        }else{
            SaleService.updateSale(id, data)
            .then(response => {
                setSuccessMessage('Venta actualizada correctamente')
                navigate('/sales')
            })
            .catch(error => displayErrorMessages(error.response.data))
        }
        setSaving(false)
    };

    const loadSale = async () => {
        SaleService.getSale(id)
        .then(response => {
            setValue('invoice', response.data.invoice)
            setValue('invoice_date', response.data.invoice_date)
            setValue('reception_date', response.data.reception_date)
            setValue('purchase_order', response.data.purchase_order)
            setValue('customer', response.data.customer.id)
            setSelectedCustomer({value: response.data.customer.id, label: response.data.customer.business_name})
            setValue('seller', response.data.seller.id)
            setSelectedSeller({value: response.data.seller.id, label: response.data.seller.identifier + ' - ' + response.data.seller.alias})
            setValue('country', response.data.country.id)
            setSelectedCountry({value: response.data.country.id, label: response.data.country.name})
            setValue('project', response.data.project)
            setValue('net_sale', response.data.net_sale)
            setValue('tax', response.data.tax)
            setValue('payment_term', response.data.payment_term.id)
            setSelectedPaymentTerm({value: response.data.payment_term.id, label: response.data.payment_term.name})
            setValue('comission_percentage', response.data.comission_percentage)
            setValue('currency', response.data.currency.id)
            setSelectedCurrency({value: response.data.currency.id, label: response.data.currency.name})
            setValue('status', response.data.status)
            setSelectedStatus({value: response.data.status, label: response.data.status})
        })
    }

    useEffect(() => {
        loadSellers();
        loadCountries();
        loadCustomers();
        loadPaymentTerms();
        loadCurrrencies();
        if(id){
            loadSale();
        }
    }, []);


    return <Layout title={!id ? 'Nueva venta': 'Editar venta'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Factura</label>
                    <input type="text" className='form-control' {...register('invoice')} />
                    {errors.invoice ? <div className='text-danger'>{errors.invoice?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Fecha de factura</label>
                    <input type="date" className='form-control' {...register('invoice_date')} />
                    {errors.invoice_date ? <div className='text-danger'>{errors.invoice_date?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Fecha de recepción</label>
                    <input type="date" className='form-control' {...register('reception_date')} />
                    {errors.reception_date ? <div className='text-danger'>{errors.reception_date?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Orden de compra</label>
                    <input type="text" className='form-control' {...register('purchase_order')} />
                    {errors.purchase_order ? <div className='text-danger'>{errors.purchase_order?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <div className='d-flex'>
                        <label className='form-label ms-1'>Cliente</label>
                        {hasPermissions(['customers.cus_add_customer'], userPermissions) &&
                        <div className='ms-1 mt-n1'>
                            (
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/customers/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadCustomers(true)}}><i className='dripicons-retweet'></i></button>
                            )
                        </div>
                        }
                    </div>
                    
                    <Controller
                    name="customer"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={customers}
                    onChange={(option, action) => {setValue('customer', option ? option.value : ''); setSelectedCustomer(option)}}
                    value={selectedCustomer}>
                    </Select>
        
                    {errors.user ? <div className='text-danger'>{errors.user?.message}</div> : ''}
                </div>
                {
                    canViewSalesFromOtherSellers &&
                    <div className='mb-3 col-md-6'>
                        <div className='d-flex'>
                            <label className='form-label ms-1'>Ejecutivo</label>
                            {hasPermissions(['sales.cus_add_seller'], userPermissions) &&
                            <div className='ms-1 mt-n1'>
                                (
                                <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/sellers/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                                <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadSellers(true)}}><i className='dripicons-retweet'></i></button>
                                )
                            </div>
                            }
                        </div>
                        
                        <Controller
                        name="seller"
                        control={control}
                        render={({ field }) => (
                            <input type="hidden" {...field} value={field.value} />
                        )}
                        >
                        </Controller>
                        
                        <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={sellers}
                        onChange={(option, action) => {setValue('seller', option ? option.value : ''); setSelectedSeller(option)}}
                        value={selectedSeller}>
                        </Select>
            
                        {errors.seller ? <div className='text-danger'>{errors.seller?.message}</div> : ''}
                    </div>
                }
                <div className='mb-3 col-md-6'>
                    <div className='d-flex'>
                        <label className='form-label ms-1'>País</label>
                        {hasPermissions(['countries.cus_add_country'], userPermissions) &&
                        <div className='ms-1 mt-n1'>
                            (
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/countries/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadCountries(true)}}><i className='dripicons-retweet'></i></button>
                            )
                        </div>
                        }
                    </div>
                    
                    <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={countries}
                    onChange={(option, action) => {setValue('country', option ? option.value : ''); setSelectedCountry(option)}}
                    value={selectedCountry}>
                    </Select>
        
                    {errors.country ? <div className='text-danger'>{errors.country?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Proyecto</label>
                    <input type="text" className='form-control' {...register('project')} />
                    {errors.project ? <div className='text-danger'>{errors.project?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Venta neta</label>
                    <input type="number" step="0.01" className='form-control' {...register('net_sale')} />
                    {errors.net_sale ? <div className='text-danger'>{errors.net_sale?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <div className='d-flex'>
                        <label className='form-label ms-1'>Moneda</label>
                        {hasPermissions(['sales.cus_add_currency'], userPermissions) &&
                        <div className='ms-1 mt-n1'>
                            (
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/currencies/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadCurrrencies(true)}}><i className='dripicons-retweet'></i></button>
                            )
                        </div>
                        }
                    </div>
                    
                    <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={currencies}
                    onChange={(option, action) => {setValue('currency', option ? option.value : ''); setSelectedCurrency(option)}}
                    value={selectedCurrency}>
                    </Select>
        
                    {errors.currency ? <div className='text-danger'>{errors.currency?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>% Impuesto</label>
                    <input type="number" step="0.01" className='form-control' {...register('tax')} />
                    {errors.tax ? <div className='text-danger'>{errors.tax?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <div className='d-flex'>
                        <label className='form-label ms-1'>Términos de pago</label>
                        {hasPermissions(['sales.cus_add_payment_term'], userPermissions) &&
                        <div className='ms-1 mt-n1'>
                            (
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/payment-terms/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadPaymentTerms(true)}}><i className='dripicons-retweet'></i></button>
                            )
                        </div>
                        }
                    </div>
                    
                    <Controller
                    name="payment_term"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={paymentTerms}
                    onChange={(option, action) => {setValue('payment_term', option ? option.value : ''); setSelectedPaymentTerm(option)}}
                    value={selectedPaymentTerm}>
                    </Select>
        
                    {errors.payment_term ? <div className='text-danger'>{errors.payment_term?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>% Comisión</label>
                    <input type="number" step="0.01" className='form-control' {...register('comission_percentage')} />
                    {errors.comission_percentage ? <div className='text-danger'>{errors.comission_percentage?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label ms-1'>Estatus</label>
                    <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={status}
                    onChange={(option, action) => {setValue('status', option ? option.value : ''); setSelectedStatus(option)}}
                    value={selectedStatus}>
                    </Select>
        
                    {errors.status ? <div className='text-danger'>{errors.status?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/sales')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default SaleForm