import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';

import Layout from '../../layout/Layout';
import CardMenu from '../../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages} from '../../../utils/messages';
import SaleService from '../../../services/sales/sale.service';


const PaymentForm = () => {
    const navigate = useNavigate();
    const { saleId, id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [paymentTypes, setPaymentTypes] = useState([
        {value: 'Venta', label: 'Venta'},
        {value: 'Comisión', label: 'Comisión'}
    ]);
    const [selectedPaymentType, setSelectedPaymentType] = useState();

    let formFields = {
        sale: yup.string(),
        type: yup.string().required('Este campo es requerido'),
        amount: yup.number()
            .positive('Este campo debe ser un número positivo')
            .transform(value => (isNaN(value) ? undefined : value))
            .required('Este campo es requerido'),
        payment_date: yup.string().required('Este campo es requerido')
    }
    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            sale: saleId
        }
    });

    const onSubmitHandler = (data) => {
        setSaving(true)
        if(!id){
            SaleService.createPayment(data)
            .then(response => {
                setSuccessMessage('Pago creado exitosamente')
                navigate('/sales/' + saleId + '/payments')             
            })
            .catch(error => displayErrorMessages(error.response.data))
        }else{
            SaleService.updatePayment(id, data)
            .then(response => {
                setSuccessMessage('Pago actualizado correctamente')
                navigate('/sales/' + saleId + '/payments')
            })
            .catch(error => displayErrorMessages(error.response.data))
        }
        setSaving(false)
    };

    const loadPayment = async () => {
        SaleService.getPayment(id)
        .then(response => {
            setValue('type', response.data.type)
            setSelectedPaymentType({value: response.data.type, label: response.data.type})
            setValue('amount', response.data.amount)
            setValue('payment_date', response.data.payment_date)
        })
    }

    useEffect(() => {
        if(id){
            loadPayment();
        }
    }, []);


    return <Layout title={!id ? 'Nuevo pago': 'Editar pago'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label ms-1'>Tipo</label>
                    
                    <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={paymentTypes}
                    onChange={(option, action) => {setValue('type', option ? option.value : ''); setSelectedPaymentType(option)}}
                    value={selectedPaymentType}>
                    </Select>
        
                    {errors.type ? <div className='text-danger'>{errors.type?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Monto</label>
                    <input type="number" step="0.01" className='form-control' {...register('amount')} />
                    {errors.amount ? <div className='text-danger'>{errors.amount?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Fecha de pago</label>
                    <input type="date" className='form-control' {...register('payment_date')} />
                    {errors.payment_date ? <div className='text-danger'>{errors.payment_date?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/sales/' + saleId + '/payments')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default PaymentForm