import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import fileDownload from 'js-file-download';

import SaleService from '../../services/sales/sale.service';
import UtilsService from '../../services/utils.service';
import Layout from '../layout/Layout'
import CardMenu from '../layout/components/CardMenu'
import { moneyFormat } from '../../utils/currencies';


const AccountStatusReport = () => {
    const [loadingData, setLoadingData] = useState(false)
    const [loadingPDF, setLoadingPDF] = useState(false)

    const [report, setReport] = useState({})
    const [showReport, setShowReport] = useState(false)
    const [reportType, setReportType] = useState('c')

    const [customers, setCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState();
    const loadCustomers = async () => {
        UtilsService.getRelatedField('customers', 'Customer')
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setCustomers(res);
        })
    }

    const [sellers, setSellers] = useState([])
    const [selectedSeller, setSelectedSeller] = useState()
    const loadSellers = async () => {
        UtilsService.getRelatedField('sales', 'Seller')
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label}
            })
            setSellers(res)
        })
    }

    let formFields = {
        customer: yup.string().required('Este campo es requerido')
    }
    const schema = yup.object().shape(formFields).required();
    
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm({
        resolver: yupResolver(schema)
    });

    let formFields2 = {
        seller: yup.string().required('Este campo es requerido')
    }
    const schema2 = yup.object().shape(formFields2).required();
    
    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, setValue: setValue2, control: control2 } = useForm({
        resolver: yupResolver(schema2)
    });

    const loadReport = (data) => {
        setShowReport(true);
        setLoadingData(true);
        SaleService.getAccountStatusReport(data)
        .then((response) => {
            setReport(response.data);
            setLoadingData(false);
        })
    }

    const downloadReport = (data) => {
        setLoadingPDF(true);
        SaleService.getAccountStatusReportPDF(data)
        .then(response => {
            fileDownload(response.data, 'estado-de-cuenta.pdf')
            setLoadingPDF(false);
        })
    }

    useEffect(() => {
        loadCustomers();
        loadSellers();
    }, []);

    return <Layout title='Estado de cuenta'>
        <CardMenu>
            <div className='mb-3'>
                <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="report" 
                        id='report1' 
                        value="c" 
                        checked={reportType == 'c' ? true : false}
                        onChange={() => {setReportType('c'); setSelectedSeller(null); setShowReport(false);}} 
                    />
                    <label className="form-check-label" htmlFor="report1">Estado de cuenta por cliente</label>
                </div>
                <div className="form-check">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="report" 
                        id='report2' 
                        value="s" 
                        checked={reportType == 's' ? true : false}
                        onChange={() => {setReportType('s'); setSelectedCustomer(null); setShowReport(false);}} 
                    />
                    <label className="form-check-label" htmlFor="report2">Estado de cuenta por ejecutivo</label>
                </div>
            </div>

            {
                reportType == 'c' ?
                <form className='row mb-3' onSubmit={handleSubmit(loadReport)}>
                    <div className='col-md-6'>
                        
                        <Controller
                        name="customer"
                        control={control}
                        render={({ field }) => (
                            <input type="hidden" {...field} value={field.value} />
                        )}
                        >
                        </Controller>
                        
                        <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="Selecciona un cliente"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={customers}
                        onChange={(option, action) => {setValue('customer', option ? option.value : ''); setSelectedCustomer(option); setShowReport(false)}}
                        value={selectedCustomer}>
                        </Select>

                        {errors.customer ? <div className='text-danger'>{errors.customer?.message}</div> : ''}
                    </div>
                    <div className='col-md-6'>
                        <button type='submit' className='btn btn-primary' disabled={loadingData}>{loadingData ? 'Espera un momento...':'Buscar'}</button>
                        {
                            !!Object.keys(report).length && showReport &&
                            <button type='submit' onClick={handleSubmit(downloadReport)} className='btn btn-info ms-1' disabled={loadingPDF}>{loadingPDF ? 'Espera un momento...':'Descargar PDF'}</button>
                        }
                    </div>
                </form>
                :
                <form className='row mb-3' onSubmit={handleSubmit2(loadReport)}>
                    <div className='col-md-6'>
                        
                        <Controller
                        name="seller"
                        control={control2}
                        render={({ field: field2 }) => (  
                            <input type="hidden" {...field2} value={field2.value} />
                        )}
                        >
                        </Controller>
                        
                        <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="Selecciona un ejecutivo"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={sellers}
                        onChange={(option2, action2) => {setValue2('seller', option2 ? option2.value : ''); setSelectedSeller(option2); setShowReport(false)}}
                        value={selectedSeller}>
                        </Select>

                        {errors2.seller ? <div className='text-danger'>{errors2.seller?.message}</div> : ''}
                    </div>
                    <div className='col-md-6'>
                        <button type='submit' className='btn btn-primary' disabled={loadingData}>{loadingData ? 'Espera un momento...':'Buscar'}</button>
                        {
                            !!Object.keys(report).length && showReport &&
                            <button type='submit' onClick={handleSubmit2(downloadReport)} className='btn btn-info ms-1' disabled={loadingPDF}>{loadingPDF ? 'Espera un momento...':'Descargar PDF'}</button>
                        }
                    </div>
                </form>
            }

            

            {
                showReport &&
                    <div className='table-responsive table-grid'>
                        <table className='table-centered table table-hover'>
                            <thead>
                                <tr>
                                    <th>Orden de compra</th>
                                    <th>No.Factura</th>
                                    <th>Fecha de factura</th>
                                    <th>Plazo (días)</th>
                                    <th>Días de atraso</th>
                                    <th>Monto</th>
                                    <th>Abono</th>
                                    <th>Saldo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !loadingData ?
                                        Object.keys(report).length ?
                                            Object.keys(report).map((key, i) => {
                                                return <tr key={i}>
                                                    <td>{report[key].purchase_order}</td>
                                                    <td>{report[key].invoice}</td>
                                                    <td>{new Date(report[key].invoice_date).toLocaleDateString('es-MX')}</td>
                                                    <td>{report[key].payment_term_days}</td>
                                                    <td>{report[key].payment_days_late}</td>
                                                    <td>{moneyFormat(report[key].total_sale, 'USD')[0]}</td>
                                                    <td>{moneyFormat(report[key].deposited, 'USD')[0]}</td>
                                                    <td>{moneyFormat(report[key].balance, 'USD')[0]}</td>
                                                </tr>
                                        })
                                        :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                                    :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </CardMenu>

    </Layout>
}

export default AccountStatusReport