import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Layout from '../layout/Layout';
import DepartmentService from '../../services/departments/department.service';


const DepartmentDetail = () => {

    const [department, setDepartment] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const loadDepartment = async () => {
        DepartmentService.getDepartment(id)
        .then(response => {
            setDepartment(response.data)
        })
    }

    useEffect(() => {
        loadDepartment();
    }, [])

    return <Layout>

        <div className='row justify-content-center'>
            <div className='col-md-4'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Detalles del departamento</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Nombre:</span>
                                {department.name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Creado por:</span>
                                {department.created_by} el {new Date(department.created_at).toLocaleString('es-MX')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Última modificación por:</span>
                                {department.last_updated_by} el {new Date(department.last_updated_at).toLocaleString('es-MX')}
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-12'>
                <div className='d-flex justify-content-between mt-4 mb-2'>
                    <button className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/departments')}>Regresar</button>
                </div>
            </div>

        </div>

    </Layout>
}

export default DepartmentDetail;