import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { checkSuccessMessage } from '../../utils/messages';
import Actions from '../layout/components/Actions';
import Pagination from '../layout/components/Pagination';
import Modal from '../layout/components/Modal';
import { hasPermissions } from '../../utils/permissions';
import UserService from '../../services/users/user.service';
import UtilsService from '../../services/utils.service';


const UserList = () => {
    const permissions = useSelector(state => state.Auth.permissions)
    const modalRef = useRef()
    const location = useLocation()
    const currentPath = location.pathname + location.search;
    const [loadingData, setLoadingData] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [pagination, setPagination] = useState({previous: null, next: null})

    const filters = {
        q: searchParams.get('q'),
        status: searchParams.getAll('status').filter(element => {return element !== ''}),
        department: searchParams.getAll('department').filter(element => {return element !== ''}),
        page: searchParams.get('page') || 1,
    }
     
    const [selectedStatus, setSelectedStatus] = useState();
    const status = [
        {value: '1', label: 'Activo'},
        {value: '0', label: 'Inactivo'}
    ]
    const urlStatus = status.map((obj)=>{
        if(filters['status'].includes(obj.value.toString())) return obj
    });

    const [selectedDepartments, setSelectedDepartments] = useState();
    const [departments, setDepartments] = useState([]);
    const loadDepartments = async () => {
        UtilsService.getRelatedField('departments', 'Department')
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setDepartments(res);

            const urlDepartments = res.map((obj)=>{
                if(filters['department'].includes(obj.value.toString())) return obj
            });
            setSelectedDepartments(urlDepartments);
        })
    }

    const [users, setUsers] = useState([]);
    const loadUsers = async () => {
        setLoadingData(true);
        UserService.getUsers(filters)
        .then((response) => {
            setUsers(response.data.results);
            setPagination({
                previous: response.data.previous,
                next: response.data.next 
            })
            setLoadingData(false);
        })
    }

    useEffect(() => {
        loadUsers();
        loadDepartments();
        checkSuccessMessage();
        setSelectedStatus(urlStatus);
    }, []);
    

    return <Layout title='Usuarios'>
            <CardMenu>
                <div className="mb-3 text-end">
                    <button className='btn btn-light me-1' onClick={() => {modalRef.current.toggleModal()}}>Filtros</button>
                    {hasPermissions(['users.cus_add_user'], permissions) && 
                        <Link 
                        to={'/users/add' + '?prev_url=' + currentPath}
                        className='btn btn-primary'>
                            Nuevo usuario
                        </Link>
                    }
                </div>
                <div className='table-responsive table-grid'>
                    <table className='table-centered table table-hover'>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Apellidos</th>
                                <th>Correo electrónico</th>
                                <th>Departamento</th>
                                <th>Estatus</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !loadingData ?
                                    users.length ? 
                                        users.map((usr, i) => {
                                            return <tr key={i}>
                                                <td>
                                                    <div>
                                                    {usr.is_superuser && 
                                                        <OverlayTrigger placement="left" overlay={<Tooltip>Superusuario</Tooltip>}>
                                                            <i className='me-1 text-warning dripicons-star'></i>
                                                        </OverlayTrigger>
                                                    }
                                                    {usr.first_name}
                                                    </div>
                                                </td>
                                                <td>{usr.last_name}</td>
                                                <td>{usr.email}</td>
                                                <td>{usr.department?.name}</td>
                                                <td>
                                                    {usr.is_active ? 
                                                    <span className="me-1 badge-success-lighten badge">Activo</span>
                                                    :
                                                    <span className="me-1 badge-danger-lighten badge">Inactivo</span>
                                                    }
                                                </td>
                                                <td>
                                                    <Actions
                                                    actions={[
                                                        {'label': 'Detalles', 'link': 'users/' + usr.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['users.cus_view_user'], permissions)},
                                                        {'label': 'Editar', 'link': 'users/edit/' + usr.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['users.cus_change_user'], permissions)},
                                                        {'label': 'Eliminar', 'link': 'users/delete/' + usr.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['users.cus_delete_user'], permissions)},
                                                        {'label': 'Reestablecer contraseña', 'link': 'users/edit/password/' + usr.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['users.cus_change_user'], permissions)},
                                                    ]}></Actions>
                                                </td>
                                            </tr>
                                    })
                                    :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                                :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                            }
                        </tbody>
                    </table>
                    <Pagination previous={pagination['previous']} next={pagination['next']}></Pagination>
                </div>
            </CardMenu>
                    

        <Modal title='Filtros' ref={modalRef}>
            <form method='GET'>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Palabra clave</label>
                    <input type="text" className='form-control w-100' name='q' defaultValue={filters['q']} />
                </div>
                <div className='mb-3'>
                    <label htmlFor="status" className="me-2">Estatus</label>
                    <Select
                        name='status'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={status}
                        onChange={(option, action) => {setSelectedStatus(option)}}
                        value={selectedStatus}>
                    </Select>
                </div>
                <div className='mb-3'>
                    <label htmlFor="department" className="me-2">Departamento</label>
                    <Select
                        name='department'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={departments}
                        onChange={(option, action) => {setSelectedDepartments(option)}}
                        value={selectedDepartments}>
                    </Select>
                </div>
                <div className="mb-3 text-end">
                    <input type="submit" className='btn btn-primary' value='Buscar' />
                </div>
            </form>
        </Modal>
        
    </Layout>;
}

export default UserList;