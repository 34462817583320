import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';

import { menuConfig } from '../../../utils/menuConfig';
import { hasPermissions, hasOneOfPermissions } from '../../../utils/permissions';



const Menu = (props) => {
    const [menuIsOpen, setMenuIsOpen] = useState(menuConfig);
    const userPermissions = useSelector(state => state.Auth.permissions);
    const location = useLocation()
    const currentPath = location.pathname + location.search;

    return (
        <div className="topnav shadow-sm">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg topnav-menu navbar-dark">
                    <Collapse in={props.isMenuOpened} className="navbar-collapse" id="topnav-menu-content">
                        <div>
                            <ul className="navbar-nav" id="main-side-menu">
                                {Object.keys(menuConfig).map((key, i) => {
                                    if(!menuConfig[key].submenus){
                                        if(hasPermissions([menuConfig[key].permission], userPermissions)){
                                            return <Link
                                                    className='nav-link'
                                                    to={menuConfig[key].link}>
                                                        {menuConfig[key].title}
                                                    </Link>
                                        }
                                    }else{
                                        const menuPermissions = menuConfig[key].submenus.map(p => p.permission);
                                        const show = window.screen.width <= 768 && menuIsOpen[key]['menuIsOpen'];

                                        if(hasOneOfPermissions(menuPermissions, userPermissions)){
                                            return (
                                                <li key={i} className="nav-item dropdown">
                                                    <Link 
                                                        className='nav-link dropdown-toggle arrow-none'
                                                        to={currentPath}
                                                        role='button'
                                                        onClick={() => {menuIsOpen[key].menuIsOpen = !menuIsOpen[key].menuIsOpen; setMenuIsOpen({...menuIsOpen});}}         
                                                    >
                                                        {menuConfig[key].title} {menuConfig[key].submenus && <div className="arrow-down"></div>}
                                                    </Link>
                                                    {menuConfig[key].submenus && 
                                                        <div className={classNames('dropdown-menu', {'show': show})}>
                                                            {menuConfig[key].submenus.map((s, j) => {
                                                                if(hasPermissions([s.permission], userPermissions)){
                                                                    return (<Link key={j} to={s.link} className='dropdown-item'>
                                                                        {s.title}
                                                                    </Link>)
                                                                }
                                                                return <></>;
                                                            })}
                                                        </div>
                                                    }
                                                </li>
                                            )
                                        }
                                    }
                                    return <></>;
                                })}
                            </ul>
                        </div>
                    </Collapse>
                </nav>
            </div>
        </div>
    );
}

export default Menu;