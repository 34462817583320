import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import { hasOneOfPermissions } from '../../../utils/permissions';
import { checkSuccessMessage } from '../../../utils/messages';
import Actions from '../../layout/components/Actions';
import Layout from '../../layout/Layout'
import CardMenu from '../../layout/components/CardMenu'
import SaleService from '../../../services/sales/sale.service';
import { moneyFormat } from '../../../utils/currencies';


const PaymentList = () => {
    const permissions = useSelector(state => state.Auth.permissions)
    const [loadingData, setLoadingData] = useState(false)
    const { id } = useParams();
    const [sale, setSale] = useState({});
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation()
    const currentPath = location.pathname + location.search;

    const loadSale = async () => {
        setLoadingData(true);
        SaleService.getSale(id)
        .then(response => {
            setSale(response.data)
            setLoadingData(false);
        })
    }

    useEffect(() => {
        loadSale();
        checkSuccessMessage();
    }, []);

    return <Layout title={'Pagos de venta ' + sale.purchase_order}>
        <CardMenu>
            <div className="mb-3 text-end">
                <button className='btn btn-light me-1' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/sales')}>Regresar a ventas</button>
                {hasOneOfPermissions(['sales.cus_add_sale', 'sales.cus_change_sale'], permissions) && 
                    <Link 
                    to={'/sales/' + id + '/payments/add' + '?prev_url=' + currentPath}
                    className='btn btn-primary'>
                        Nuevo pago
                    </Link>
                }
            </div>
            <div className='table-responsive table-grid'>
                <table className='table-centered table table-hover'>
                    <thead>
                        <tr>
                            <th>Tipo</th>
                            <th>Monto</th>
                            <th>Fecha</th>
                            <th>Creado por</th>
                            <th>Última modificación</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loadingData ?
                                sale.payments?.length ? 
                                    sale.payments.map((obj, i) => {
                                        return <tr key={i}>
                                            <td>{obj.type}</td>
                                            <td>{moneyFormat(obj.amount, 'USD')[0]}</td>
                                            <td>{new Date(obj.payment_date).toLocaleDateString('es-MX')}</td>
                                            <td>{obj.created_by} el {new Date(obj.created_at).toLocaleString('es-MX')}</td>
                                            <td>{obj.last_updated_by} el {new Date(obj.last_updated_at).toLocaleString('es-MX')}</td>
                                            <td>
                                                <Actions
                                                actions={[
                                                    {'label': 'Editar', 'link': '/sales/' + id + '/payments/edit/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasOneOfPermissions(['sales.cus_add_sale', 'sales.cus_change_sale'], permissions)},
                                                    {'label': 'Eliminar', 'link': '/sales/' + id + '/payments/delete/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasOneOfPermissions(['sales.cus_add_sale', 'sales.cus_change_sale'], permissions)}
                                                ]}></Actions>
                                            </td>
                                        </tr>
                                })
                                :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                            :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </CardMenu>
    </Layout>
}

export default PaymentList