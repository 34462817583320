import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Layout from '../layout/Layout';
import CustomerService from '../../services/customers/customer.service';

const CustomerDetail = () => {
    const [customer, setCustomer] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const loadCustomer = async () => {
        CustomerService.getCustomer(id)
        .then(response => {
            setCustomer(response.data)
        })
    }

    useEffect(() => {
        loadCustomer();
    }, [])

    return <Layout>

        <div className='row justify-content-center'>
            <div className='col-md-4'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Detalles del cliente</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Razón social:</span>
                                {customer.business_name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Línea 1 de la dirección:</span>
                                {customer.line1}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Línea 2 de la dirección:</span>
                                {customer.line2}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Provincia:</span>
                                {customer.province}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Corregimiento:</span>
                                {customer.township}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Contacto:</span>
                                {customer.contact}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Teléfono:</span>
                                {customer.phone}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Correo electrónico:</span>
                                {customer.email}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Creado por:</span>
                                {customer.created_by} el {new Date(customer.created_at).toLocaleString('es-MX')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Última modificación por:</span>
                                {customer.last_updated_by} el {new Date(customer.last_updated_at).toLocaleString('es-MX')}
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-md-4'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Cuentas por pagar</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Contacto:</span>
                                {customer.ap_contact}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Teléfono:</span>
                                {customer.ap_phone}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Correo electrónico:</span>
                                {customer.ap_email}
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-12'>
                <div className='d-flex justify-content-between mt-4 mb-2'>
                    <button className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/customers')}>Regresar</button>
                </div>
            </div>

        </div>

    </Layout>
}

export default CustomerDetail;