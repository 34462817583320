import api from '../api'


class SellerService {
    getSellers(params={}){
        return api.get('/api/sales/sellers/', {params});
    }

    getSeller(id){
        return api.get('/api/sales/sellers/' + id + '/')
    }

    createSeller(payload={}){
        return api.post('/api/sales/sellers/', payload)
    }

    updateSeller(id, payload={}){
        return api.put('/api/sales/sellers/' + id + '/', payload)
    }

    deleteSeller(id){
        return api.delete('/api/sales/sellers/' + id + '/')
    }
}

export default new SellerService()