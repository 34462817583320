import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';

import { logout } from '../../../redux/actions/auth';

import logoLg from '../../../assets/images/logos/capsapan-b.png';
import logoSm from '../../../assets/images/logos/capsapan-m-b.png';
import profileIcon from '../../../assets/images/profile-icon.jpg';


const Topbar = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentPath = location.pathname + location.search;
    const [isopen, setIsopen] = useState(false);
    const { userName , userEmail} = useSelector(state => {
        return {userName: state.Auth.name, userEmail: state.Auth.email }
    });

    /**
     * Toggle the leftmenu when having mobile screen
     */
     const handleLeftMenuCallBack = () => {
        setIsopen((prevState) => !prevState);
        if (props.openLeftMenuCallBack) props.openLeftMenuCallBack();
     };

    return <div className="navbar-custom topnav-navbar topnav-navbar-dark">
        <div className="container-fluid">
            <Link to='/' className='topnav-logo'>
                <span className="topnav-logo-lg"><img src={logoLg} alt="CAPSA" height="35"/></span>
                <span className="topnav-logo-sm"><img src={logoSm} alt="CAPSA" height="50"/></span>
            </Link>            

            <ul className="list-unstyled topbar-menu float-end mb-0">
                <li className="dropdown notification-list">
                    <Dropdown>
                        <Dropdown.Toggle variant="link" id="dropdown-profile" as={Link} to={currentPath} className="nav-link dropdown-toggle nav-user arrow-none me-0">
                            <span className="account-user-avatar"> 
                                <img src={profileIcon} alt="profile-pic" className="rounded-circle"/>
                            </span>
                            <span>
                                <span className="account-user-name">{userName}</span>
                                <span className="account-position">{userEmail}</span>
                            </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu align={'end'} className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                            <button className="dropdown-item notify-item" onClick={() => {dispatch(logout());}}>
                                <span>Cerrar sesión</span>
                            </button>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>

            <Link
                to="#"
                className={classNames('navbar-toggle', { 'open': isopen })}
                onClick={handleLeftMenuCallBack}>
                <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </Link>

        </div>
    </div>
}

export default Topbar;