import { useSelector } from 'react-redux';
import { useRef, useState, useEffect } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { hasPermissions, hasOneOfPermissions } from '../../utils/permissions';
import { checkSuccessMessage } from '../../utils/messages';
import Pagination from '../layout/components/Pagination';
import Actions from '../layout/components/Actions';
import Layout from '../layout/Layout'
import CardMenu from '../layout/components/CardMenu'
import Modal from '../layout/components/Modal';
import SaleService from '../../services/sales/sale.service';
import UtilsService from '../../services/utils.service';
import { moneyFormat } from '../../utils/currencies';


const SaleList = () => {
    const permissions = useSelector(state => state.Auth.permissions)
    const modalRef = useRef()
    const location = useLocation()
    const currentPath = location.pathname + location.search;
    const [loadingData, setLoadingData] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [pagination, setPagination] = useState({previous: null, next: null})

    const filters = {
        q: searchParams.get('q'),
        status: searchParams.getAll('status').filter(element => {return element !== ''}),
        seller: searchParams.getAll('seller').filter(element => {return element !== ''}),
        customer: searchParams.getAll('customer').filter(element => {return element !== ''}),
        initial_date: searchParams.get('initial_date'),
        final_date: searchParams.get('final_date'),
        page: searchParams.get('page') || 1,
    }

    const statusBg = {
        'Entregado y cobrado': '',
        'En producción': 'table-success',
        'Entregado por cobrar': 'table-warning',
        'Con inconvenientes': 'table-danger'
    }

    
    const [selectedStatus, setSelectedStatus] = useState();
    const status = [
        {value: 'Con inconvenientes', label: 'Con inconvenientes'},
        {value: 'En producción', label: 'En producción'},
        {value: 'Entregado por cobrar', label: 'Entregado por cobrar'},
        {value: 'Entregado y cobrado', label: 'Entregado y cobrado'}
    ];
    const urlStatus = status.map((obj)=>{
        if(filters['status'].includes(obj.value.toString())) return obj
    });

    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [customers, setCustomers] = useState([])
    const loadCustomers = async () => {
        UtilsService.getRelatedField('customers', 'Customer')
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setCustomers(res);

            const urlCustomers = res.map((obj)=>{
                if(filters['customer'].includes(obj.value.toString())) return obj
            });
            setSelectedCustomers(urlCustomers);
        })
    }

    const [selectedSellers, setSelectedSellers] = useState([])
    const [sellers, setSellers] = useState([])
    const loadSellers = async () => {
        UtilsService.getRelatedField('sales', 'Seller')
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setSellers(res);

            const urlSellers = res.map((obj)=>{
                if(filters['seller'].includes(obj.value.toString())) return obj
            });
            setSelectedSellers(urlSellers);
        })
    }

    const [sales, setSales] = useState([])
    const loadSales = async () => {
        setLoadingData(true);
        SaleService.getSales(filters)
        .then((response) => {
            setSales(response.data.results);
            setPagination({
                previous: response.data.previous,
                next: response.data.next 
            })
            setLoadingData(false);
        })
    }

    useEffect(() => {
        loadSales();
        loadCustomers();
        loadSellers();
        checkSuccessMessage();
        setSelectedStatus(urlStatus);
    }, []);

    return <Layout title='Ventas'>
        <CardMenu>
            <div className="mb-3 text-end">
                <button className='btn btn-light me-1' onClick={() => {modalRef.current.toggleModal()}}>Filtros</button>
                {hasPermissions(['sales.cus_add_sale'], permissions) && 
                    <Link 
                    to={'/sales/add' + '?prev_url=' + currentPath}
                    className='btn btn-primary'>
                        Nueva venta
                    </Link>
                }
            </div>
            <div className='table-responsive table-grid'>
                <table className='table-centered table table-hover'>
                    <thead>
                        <tr>
                            <th>Factura</th>
                            <th>Fecha de factura</th>
                            <th>Orden de compra</th>
                            <th>Estatus</th>
                            <th>Cliente</th>
                            <th>Ejecutivo</th>
                            <th>Fecha tentativa de pago</th>
                            <th>Saldo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loadingData ?
                                sales.length ? 
                                    sales.map((obj, i) => {
                                        return <tr key={i} className={statusBg[obj.status]}>
                                            <td>{obj.invoice}</td>
                                            <td>{obj.invoice_date && new Date(obj.invoice_date).toLocaleDateString('es-MX')}</td>
                                            <td>{obj.purchase_order}</td>
                                            <td>{obj.status}</td>
                                            <td>{obj.customer.business_name}</td>
                                            <td>{obj.seller.identifier} - {obj.seller.alias}</td>
                                            <td>{obj.tentative_payment_date && new Date(obj.tentative_payment_date).toLocaleDateString('es-MX')}</td>
                                            <td>{moneyFormat(obj.balance, 'USD')[0]}</td>
                                            <td>
                                                <Actions
                                                actions={[
                                                    {'label': 'Pagos', 'link': 'sales/' + obj.id + '/payments' + '?prev_url=' + currentPath, 'hasPerm': hasOneOfPermissions(['sales.cus_add_sale', 'sales.cus_change_sale'], permissions)},
                                                    {'label': 'Gastos', 'link': 'sales/' + obj.id + '/expences/' + '?prev_url=' + currentPath, 'hasPerm': hasOneOfPermissions(['sales.cus_add_sale', 'sales.cus_change_sale'], permissions)},
                                                    {'label': 'Detalles', 'link': 'sales/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['sales.cus_view_sale'], permissions)},
                                                    {'label': 'Editar', 'link': 'sales/edit/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['sales.cus_change_sale'], permissions)},
                                                    {'label': 'Eliminar', 'link': 'sales/delete/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['sales.cus_delete_sale'], permissions)}
                                                ]}></Actions>
                                            </td>
                                        </tr>
                                })
                                :<tr><td colSpan={8}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                            :<tr><td colSpan={8}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                        }
                    </tbody>
                </table>
                <Pagination previous={pagination['previous']} next={pagination['next']}></Pagination>
            </div>
        </CardMenu>

        <Modal title='Filtros' ref={modalRef}>
            <form method='GET'>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Palabra clave</label>
                    <input type="text" className='form-control w-100' name='q' defaultValue={filters['q']} />
                </div>
                <div className='mb-3'>
                    <label htmlFor="status" className="me-2">Estatus</label>
                    <Select
                        name='status'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={status}
                        onChange={(option, action) => {setSelectedStatus(option)}}
                        value={selectedStatus}>
                    </Select>
                </div>
                {
                    hasPermissions(['sales.cus_view_sales_from_other_sellers'], permissions) &&
                    <div className='mb-3'>
                        <label htmlFor="seller" className="me-2">Ejecutivo</label>
                        <Select
                            name='seller'
                            className="react-select"
                            classNamePrefix="react-select"
                            isClearable
                            isMulti
                            placeholder="Selecciona una opción"
                            noOptionsMessage={() => 'No se encontraron resultados'}
                            options={sellers}
                            onChange={(option, action) => {setSelectedSellers(option)}}
                            value={selectedSellers}>
                        </Select>
                    </div>
                }
                <div className='mb-3'>
                    <label htmlFor="customer" className="me-2">Cliente</label>
                    <Select
                        name='customer'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={customers}
                        onChange={(option, action) => {setSelectedCustomers(option)}}
                        value={selectedCustomers}>
                    </Select>
                </div>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Fecha inicio</label>
                    <input type="date" className='form-control w-100' name='initial_date' defaultValue={filters['initial_date']} />
                </div>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Fecha fin</label>
                    <input type="date" className='form-control w-100' name='final_date' defaultValue={filters['final_date']} />
                </div>
                <div className="mb-3 text-end">
                    <input type="submit" className='btn btn-primary' value='Buscar' />
                </div>
            </form>
        </Modal>

    </Layout>
}

export default SaleList