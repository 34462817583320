import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { setSuccessMessage, displayErrorMessages } from '../../utils/messages';
import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import CustomerService from '../../services/customers/customer.service';


const DeleteCustomerForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [customer, setCustomer] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const loadCustomer = async () => {
        CustomerService.getCustomer(id)
        .then(response => {
            setCustomer(response.data)
        })
    }

    const handleSubmit = e => {
        e.preventDefault();

        CustomerService.deleteCustomer(id)
        .then(response => {
            setSuccessMessage('Cliente eliminado exitosamente')
            navigate('/customers')      
        })
        .catch(error => displayErrorMessages(error.response.data))
    }

    useEffect(() => {
        loadCustomer();
    }, [])

    return <Layout title='Eliminar cliente'>
        <CardMenu>
            <div role="alert" className="fade bg-white alert alert-danger show">
                <h4 className="alert-heading">¿Deseas eliminar el cliente {customer.business_name}?</h4>
                <p>Esta acción no se puede deshacer</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/customers')}>Cancelar</button>
                        <button type='submit' className='btn btn-danger'>Eliminar</button>
                    </div>
                </div>
            </form>            
        </CardMenu>
    </Layout>
}

export default DeleteCustomerForm