import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Layout from '../../layout/Layout';
import CardMenu from '../../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages} from '../../../utils/messages';
import SaleService from '../../../services/sales/sale.service';


const ExpenceForm = () => {
    const navigate = useNavigate();
    const { saleId, id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    let formFields = {
        sale: yup.string(),
        description: yup.string(),
        amount: yup.number()
            .positive('Este campo debe ser un número positivo')
            .transform(value => (isNaN(value) ? undefined : value))
            .required('Este campo es requerido')
    }
    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            sale: saleId
        }
    });

    const onSubmitHandler = (data) => {
        setSaving(true)
        if(!id){
            SaleService.createExpence(data)
            .then(response => {
                setSuccessMessage('Gasto creado exitosamente')
                navigate('/sales/' + saleId + '/expences')             
            })
            .catch(error => displayErrorMessages(error.response.data))
        }else{
            SaleService.updateExpence(id, data)
            .then(response => {
                setSuccessMessage('Gasto actualizado correctamente')
                navigate('/sales/' + saleId + '/expences')
            })
            .catch(error => displayErrorMessages(error.response.data))
        }
        setSaving(false)
    };

    const loadExpence = async () => {
        SaleService.getExpence(id)
        .then(response => {
            setValue('description', response.data.description)
            setValue('amount', response.data.amount)
        })
    }

    useEffect(() => {
        if(id){
            loadExpence();
        }
    }, []);


    return <Layout title={!id ? 'Nuevo gasto': 'Editar gasto'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Descripción</label>
                    <input type="text" className='form-control' {...register('description')} />
                    {errors.description ? <div className='text-danger'>{errors.description?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Monto</label>
                    <input type="number" step="0.01" className='form-control' {...register('amount')} />
                    {errors.amount ? <div className='text-danger'>{errors.amount?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/sales/' + saleId + '/expences')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default ExpenceForm