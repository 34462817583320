import { useRef, useState, useEffect } from 'react';
import Select from 'react-select';
import { useSearchParams } from 'react-router-dom';

import SaleService from '../../services/sales/sale.service';
import UtilsService from '../../services/utils.service';
import Layout from '../layout/Layout'
import CardMenu from '../layout/components/CardMenu'
import Modal from '../layout/components/Modal';
import { moneyFormat } from '../../utils/currencies';


const ExecutiveSalesReport = () => {
    const modalRef = useRef()
    const [loadingData, setLoadingData] = useState(false)
    const now = new Date();
    const [searchParams, setSearchParams] = useSearchParams();

    const filters = {
        seller: searchParams.getAll('seller').filter(element => {return element !== ''}),
        initial_date: searchParams.get('initial_date') || new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0],
        final_date: searchParams.get('final_date') || new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0]
    }

    const [selectedSellers, setSelectedSellers] = useState([])
    const [sellers, setSellers] = useState([])
    const loadSellers = async () => {
        UtilsService.getRelatedField('sales', 'Seller')
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setSellers(res);

            const urlSellers = res.map((obj)=>{
                if(filters['seller'].includes(obj.value.toString())) return obj
            });
            setSelectedSellers(urlSellers);
        })
    }

    const [report, setReport] = useState({})
    const loadReport = async () => {
        setLoadingData(true);
        SaleService.getExecutiveSalesReport(filters)
        .then((response) => {
            setReport(response.data);
            setLoadingData(false);
        })
    }

    useEffect(() => {
        loadReport();
        loadSellers();
    }, []);

    return <Layout title={'Ventas por ejecutivo del ' + filters['initial_date'] + ' al ' + filters['final_date']}>
        <CardMenu>
            <div className="mb-3 text-end">
                <button className='btn btn-light me-1' onClick={() => {modalRef.current.toggleModal()}}>Filtros</button>
            </div>
            <div className='table-responsive table-grid'>
                <table className='table-centered table table-hover'>
                    <thead>
                        <tr>
                            <th>Ejecutivo</th>
                            <th>Venta neta</th>
                            <th>Venta bruta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loadingData ?
                                Object.keys(report).length ? 
                                    Object.keys(report).map((key, i) => {
                                        return <tr key={i}>
                                            <td>{key}</td>
                                            <td>{moneyFormat(report[key].net_sale, 'USD')[0]}</td>
                                            <td>{moneyFormat(report[key].total_sale, 'USD')[0]}</td>
                                        </tr>
                                })
                                :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                            :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </CardMenu>

        <Modal title='Filtros' ref={modalRef}>
            <form method='GET'>
                <div className='mb-3'>
                    <label htmlFor="customer" className="me-2">Ejecutivo</label>
                    <Select
                        name='seller'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={sellers}
                        onChange={(option, action) => {setSelectedSellers(option)}}
                        value={selectedSellers}>
                    </Select>
                </div>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Fecha inicio</label>
                    <input type="date" className='form-control w-100' name='initial_date' defaultValue={filters['initial_date']} />
                </div>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Fecha fin</label>
                    <input type="date" className='form-control w-100' name='final_date' defaultValue={filters['final_date']} />
                </div>
                <div className="mb-3 text-end">
                    <input type="submit" className='btn btn-primary' value='Buscar' />
                </div>
            </form>
        </Modal>

    </Layout>
}

export default ExecutiveSalesReport