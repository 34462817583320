import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages} from '../../utils/messages';
import DepartmentService from '../../services/departments/department.service';


const DepartmentForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    let formFields = {
        name: yup.string().required('Este campo es requerido')
    }
    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = (data) => {
        setSaving(true)
        if(!id){
            DepartmentService.createDepartment(data)
            .then(response => {
                setSuccessMessage('Departamento creado exitosamente')
                navigate('/departments')             
            })
            .catch(error => displayErrorMessages(error.response.data))
        }else{
            DepartmentService.updateDepartment(id, data)
            .then(response => {
                setSuccessMessage('Departamento actualizado correctamente')
                navigate('/departments')
            })
            .catch(error => displayErrorMessages(error.response.data))
        }
        setSaving(false)
    };

    const loadDepartment = async () => {
        DepartmentService.getDepartment(id)
        .then(response => {
            setValue('name', response.data.name)
        })
    }

    useEffect(() => {
        if(id){
            loadDepartment();
        }
    }, []);


    return <Layout title={!id ? 'Nuevo departamento': 'Editar departamento'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Nombre</label>
                    <input type="text" className='form-control' {...register('name')} />
                    {errors.name ? <div className='text-danger'>{errors.name?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/departments')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default DepartmentForm