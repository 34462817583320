import api from '../api'

class SaleService {
    getSales(params={}){
        return api.get('/api/sales/', {params});
    }

    getSale(id){
        return api.get('/api/sales/' + id + '/')
    }

    createSale(payload={}){
        return api.post('/api/sales/', payload)
    }

    updateSale(id, payload={}){
        return api.put('/api/sales/' + id + '/', payload)
    }

    deleteSale(id){
        return api.delete('/api/sales/' + id + '/')
    }

    getPayment(id){
        return api.get('/api/sales/payments/' + id + '/')
    }

    createPayment(payload={}){
        return api.post('/api/sales/payments/', payload)
    }

    updatePayment(id, payload={}){
        return api.put('/api/sales/payments/' + id + '/', payload)
    }

    deletePayment(id){
        return api.delete('/api/sales/payments/' + id + '/')
    }

    getExpence(id){
        return api.get('/api/sales/expences/' + id + '/')
    }

    createExpence(payload={}){
        return api.post('/api/sales/expences/', payload)
    }

    updateExpence(id, payload={}){
        return api.put('/api/sales/expences/' + id + '/', payload)
    }

    deleteExpence(id){
        return api.delete('/api/sales/expences/' + id + '/')
    }

    getCustomerSalesReport(params={}){
        return api.get('/api/sales/customer-sales-report/', {params})
    }

    getExecutiveSalesReport(params={}){
        return api.get('/api/sales/executive-sales-report/', {params})
    }

    getAccountStatusReport(params={}){
        return api.get('/api/sales/account-status-report/', {params})
    }

    getAccountStatusReportPDF(params={}){
        params = {...params, pdf:true}
        return api.get('/api/sales/account-status-report/', {params, responseType: 'blob'})
    }

}

export default new SaleService()