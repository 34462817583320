import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Layout from '../layout/Layout';
import SaleService from '../../services/sales/sale.service';
import { moneyFormat } from '../../utils/currencies';


const SaleDetail = () => {

    const [sale, setSale] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const loadSale = async () => {
        SaleService.getSale(id)
        .then(response => {
            response.data.net_sale = moneyFormat(response.data.net_sale, 'USD')[0]
            response.data.itbms = moneyFormat(response.data.itbms, 'USD')[0]
            response.data.total_sale = moneyFormat(response.data.total_sale, 'USD')[0]
            response.data.balance = moneyFormat(response.data.balance, 'USD')[0]
            response.data.comission = moneyFormat(response.data.comission, 'USD')[0]
            response.data.comission_balance = moneyFormat(response.data.comission_balance, 'USD')[0]
            response.data.total_cost = moneyFormat(response.data.total_cost, 'USD')[0]
            response.data.iva_reservation = moneyFormat(response.data.iva_reservation, 'USD')[0]
            response.data.utility = moneyFormat(response.data.utility, 'USD')[0]
            setSale(response.data)
        })
    }

    useEffect(() => {
        loadSale();
    }, [])

    return <Layout>

        <div className='row justify-content-center'>
            <div className='col-md-3'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Detalles de venta</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Factura:</span>
                                {sale.invoice}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Fecha tentativa de pago:</span>
                                {new Date(sale.tentative_payment_date).toLocaleDateString('es-MX')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Fecha de factura:</span>
                                {sale.invoice_date && new Date(sale.invoice_date).toLocaleDateString('es-MX')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Orden de compra:</span>
                                {sale.purchase_order}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Fecha de recepción:</span>
                                {new Date(sale.reception_date).toLocaleDateString('es-MX')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Cliente:</span>
                                {sale.customer?.business_name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Estatus:</span>
                                {sale.status}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Venta neta:</span>
                                {sale.net_sale}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Impuesto:</span>
                                {sale.tax}%
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>ITBMS:</span>
                                {sale.itbms}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Venta total:</span>
                                {sale.total_sale}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Moneda:</span>
                                {sale.currency?.code} - {sale.currency?.name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>País:</span>
                                {sale.country?.name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Ejecutivo:</span>
                                {sale.seller?.identifier} - {sale.seller?.alias}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Proyecto:</span>
                                {sale.project}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Creado por:</span>
                                {sale.created_by} el {new Date(sale.created_at).toLocaleString('es-MX')}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Última modificación por:</span>
                                {sale.last_updated_by} el {new Date(sale.last_updated_at).toLocaleString('es-MX')}
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-md-3'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Detalle de saldos</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Saldo:</span>
                                {sale.balance}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Términos de pago:</span>
                                {sale.payment_term?.name}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Pagos:</span>
                                <div className='table-responsive'>
                                    <table className='table-centered table table-hover'>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Monto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sale.payments?.map((obj) => {
                                                    if(obj.type == 'Venta'){
                                                        return <tr>
                                                            <td>{new Date(obj.payment_date).toLocaleDateString('es-MX')}</td>
                                                            <td>{moneyFormat(obj.amount, 'USD')[0]}</td>
                                                        </tr>
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            <div className='col-md-3'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Comisiones</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>% Comisión:</span>
                                {sale.comission_percentage}%
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Comisión:</span>
                                {sale.comission}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Saldo de comisión:</span>
                                {sale.comission_balance}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Pagos:</span>
                                <div className='table-responsive'>
                                    <table className='table-centered table table-hover'>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Monto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sale.payments?.map((obj) => {
                                                    if(obj.type == 'Comisión'){
                                                        return <tr>
                                                            <td>{new Date(obj.payment_date).toLocaleDateString('es-MX')}</td>
                                                            <td>{moneyFormat(obj.amount, 'USD')[0]}</td>
                                                        </tr>
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div className='col-md-3'>
                <div className="my-3 border-bottom">
                    <h4 className="font-weight-normal">Gastos</h4>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <ul className="list-unstyled">
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Costo total:</span>
                                {sale.total_cost}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Reserva IVA:</span>
                                {sale.iva_reservation}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Utilidad:</span>
                                {sale.utility}
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>% Utilidad:</span>
                                {sale.utility_percentage}%
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Retorno sobre inversión:</span>
                                {sale.return_on_investment}%
                            </li>
                            <li className='mb-2'>
                                <span className='fw-bold me-1'>Gastos:</span>
                                <div className='table-responsive'>
                                    <table className='table-centered table table-hover'>
                                        <thead>
                                            <tr>
                                                <th>Descripción</th>
                                                <th>Monto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sale.expences?.map((obj) => {
                                                    return <tr><td>{obj.description}</td><td>{moneyFormat(obj.amount, 'USD')[0]}</td></tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div className='col-12'>
                <div className='d-flex justify-content-between mt-4 mb-2'>
                    <button className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/sales')}>Regresar</button>
                </div>
            </div>

        </div>

    </Layout>
}

export default SaleDetail;