import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages} from '../../utils/messages';
import CustomerService from '../../services/customers/customer.service';


const CustomerForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    let formFields = {
        business_name: yup.string().required('Este campo es requerido'),
        line1: yup.string().required('Este campo es requerido'),
        line2: yup.string(),
        province: yup.string().required('Este campo es requerido'),
        township: yup.string().required('Este campo es requerido'),
        contact: yup.string().required('Este campo es requerido'),
        phone: yup.string().required('Este campo es requerido'),
        email: yup.string().required('Este campo es requerido'),
        ap_contact: yup.string().required('Este campo es requerido'),
        ap_phone: yup.string().required('Este campo es requerido'),
        ap_email: yup.string().required('Este campo es requerido')
    }
    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = (data) => {
        setSaving(true)
        if(!id){
            CustomerService.createCustomer(data)
            .then(response => {
                setSuccessMessage('Cliente creado exitosamente')
                navigate('/customers')             
            })
            .catch(error => displayErrorMessages(error.response.data))
        }else{
            CustomerService.updateCustomer(id, data)
            .then(response => {
                setSuccessMessage('Cliente actualizado correctamente')
                navigate('/customers')
            })
            .catch(error => displayErrorMessages(error.response.data))
        }
        setSaving(false)
    };

    const loadCustomer = async () => {
        CustomerService.getCustomer(id)
        .then(response => {
            setValue('business_name', response.data.business_name)
            setValue('line1', response.data.line1)
            setValue('line2', response.data.line2)
            setValue('province', response.data.province)
            setValue('township', response.data.township)
            setValue('contact', response.data.contact)
            setValue('phone', response.data.phone)
            setValue('email', response.data.email)
            setValue('ap_contact', response.data.ap_contact)
            setValue('ap_phone', response.data.ap_phone)
            setValue('ap_email', response.data.ap_email)
        })
    }

    useEffect(() => {
        if(id){
            loadCustomer();
        }
    }, []);


    return <Layout title={!id ? 'Nuevo cliente': 'Editar cliente'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='col-12 mb-3 border-bottom'>
                    <h4 className='font-weight-normal'>Empresa</h4>
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Razón social</label>
                    <input type="text" className='form-control' {...register('business_name')} />
                    {errors.business_name ? <div className='text-danger'>{errors.business_name?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Línea 1 de la dirección</label>
                    <input type="text" className='form-control' {...register('line1')} />
                    {errors.line1 ? <div className='text-danger'>{errors.line1?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Línea 2 de la dirección</label>
                    <input type="text" className='form-control' {...register('line2')} />
                    {errors.line2 ? <div className='text-danger'>{errors.line2?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Provincia</label>
                    <input type="text" className='form-control' {...register('province')} />
                    {errors.province ? <div className='text-danger'>{errors.province?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Corregimiento</label>
                    <input type="text" className='form-control' {...register('township')} />
                    {errors.township ? <div className='text-danger'>{errors.township?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Contacto</label>
                    <input type="text" className='form-control' {...register('contact')} />
                    {errors.contact ? <div className='text-danger'>{errors.contact?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Teléfono</label>
                    <input type="tel" className='form-control' {...register('phone')} />
                    {errors.phone ? <div className='text-danger'>{errors.phone?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Correo electrónico</label>
                    <input type="email" className='form-control' {...register('email')} />
                    {errors.email ? <div className='text-danger'>{errors.email?.message}</div> : ''}
                </div>
                <div className='col-12 mb-3 border-bottom'>
                    <h4 className='font-weight-normal'>Cuentas por pagar</h4>
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Contacto</label>
                    <input type="text" className='form-control' {...register('ap_contact')} />
                    {errors.ap_contact ? <div className='text-danger'>{errors.ap_contact?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Teléfono</label>
                    <input type="tel" className='form-control' {...register('ap_phone')} />
                    {errors.ap_phone ? <div className='text-danger'>{errors.ap_phone?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Correo electrónico</label>
                    <input type="email" className='form-control' {...register('ap_email')} />
                    {errors.ap_email ? <div className='text-danger'>{errors.ap_email?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/customers')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default CustomerForm