import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { setSuccessMessage, displayErrorMessages } from '../../utils/messages';
import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import DepartmentService from '../../services/departments/department.service';


const DeleteDepartmentForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [department, setDepartment] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const loadDepartment = async () => {
        DepartmentService.getDepartment(id)
        .then(response => {
            setDepartment(response.data)
        })
    }

    const handleSubmit = e => {
        e.preventDefault();

        DepartmentService.deleteDepartment(id)
        .then(response => {
            setSuccessMessage('Departamento eliminado exitosamente')
            navigate('/departments')      
        })
        .catch(error => displayErrorMessages(error.response.data))
    }

    useEffect(() => {
        loadDepartment();
    }, [])

    return <Layout title='Eliminar departamento'>
        <CardMenu>
            <div role="alert" className="fade bg-white alert alert-danger show">
                <h4 className="alert-heading">¿Deseas eliminar el departamento {department.name}?</h4>
                <p>Esta acción no se puede deshacer</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/departments')}>Cancelar</button>
                        <button type='submit' className='btn btn-danger'>Eliminar</button>
                    </div>
                </div>
            </form>            
        </CardMenu>
    </Layout>
}

export default DeleteDepartmentForm