import api from '../api';

class AuthService {
    
    login(email, password){
        return api.post('/api/users/login/', {email, password})
    }

    refreshToken(refresh){
        return api.post('/api/users/login/refresh/', {refresh})
    }

    verifyToken(token){
        return api.post('/api/users/login/verify/', {token})
    }

}

export default new AuthService();