import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { login } from '../../redux/actions/auth';
import { ToastContainer } from 'react-toastify';
import logo from '../../assets/images/logos/capsapan-b.png';
import 'react-toastify/dist/ReactToastify.css';


const Login = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.Auth.isAuthenticated)
    const [showPassword, setShowPassword] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const schema = yup.object().shape({
        email: yup.string().required('Este campo es requerido'),
        password: yup.string().required('Este campo es requerido'),
      }).required();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    
    useEffect(() => {
        document.body.classList.add('authentication-bg');

        return () => {
            document.body.classList.remove('authentication-bg');
        };
    }, []);


    const onSubmitHandler = (data) => {
        setSaving(true);
        dispatch(login(data['email'], data['password']))
        .then(() => {
            setSaving(false);
        });
    };
    

    if (isAuthenticated){
        let next_url = searchParams.get('next_url');
        if (next_url) return <Navigate to={next_url}/>;

        return <Navigate to='/'/>
    } 
    
    
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                            <div className="card">
                                <div className="pt-4 pb-4 text-center bg-dark card-header">
                                    <span>
                                        <img src={logo} alt="CAPSA" width="80%" height="auto" />
                                    </span>
                                </div>
                                <div className="p-4 card-body">
                                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                                        <div className="mb-3">
                                            <label className="form-label">Correo electrónico</label>
                                            <input placeholder="Ingresa tu correo electrónico" name="email" type="email" id="email" className="form-control" {...register('email')}/>
                                            {errors.email ? <div className='text-danger'>{errors.email?.message}</div> : ''}
                                        </div>
                                        <div className="mb-3"> 
                                            <label className="form-label">Contraseña</label>
                                            <div className="mb-0 input-group">
                                                <input placeholder="Ingresa tu contraseña" name="password" type={showPassword ? 'text' : 'password'} id="password" className="form-control" {...register('password')}/>
                                                <div className={classNames('input-group-text', 'input-group-password', {'show-password': showPassword,})} data-password={showPassword ? 'true' : 'false'}>
                                                    <span className="password-eye" onClick={() => {setShowPassword(!showPassword);}}></span>
                                                </div>
                                            </div>
                                            {errors.password ? <div className='text-danger'>{errors.password?.message}</div> : ''}
                                        </div>
                                        <div className="mb-3 mb-0 text-center">
                                            <button type="submit" className="btn btn-dark" disabled={isSaving}>{isSaving ? 'Espera un momento...':'Iniciar sesión'}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login