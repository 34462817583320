import api from '../api'


class CountryService {
    getCountries(params={}){
        return api.get('/api/countries/', {params});
    }

    getCountry(id){
        return api.get('/api/countries/' + id + '/')
    }

    createCountry(payload={}){
        return api.post('/api/countries/', payload)
    }

    updateCountry(id, payload={}){
        return api.put('/api/countries/' + id + '/', payload)
    }

    deleteCountry(id){
        return api.delete('/api/countries/' + id + '/')
    }
}

export default new CountryService()