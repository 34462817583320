import SaleList from '../../components/sales/SaleList'
import SaleForm from '../../components/sales/SaleForm'
import SaleDetail from '../../components/sales/SaleDetail'
import DeleteSaleForm from '../../components/sales/DeleteSaleForm'
import PaymentList from '../../components/sales/payments/PaymentList'
import PaymentForm from '../../components/sales/payments/PaymentForm'
import DeletePaymentForm from '../../components/sales/payments/DeletePaymentForm'
import ExpenceList from '../../components/sales/expences/ExpenceList'
import ExpenceForm from '../../components/sales/expences/ExpenceForm'
import DeleteExpenceForm from '../../components/sales/expences/DeleteExpenceForm'


const saleRoutes = [
    {path: '/sales', permission: ['sales.cus_view_sale'], component: <SaleList/>},
    {path: '/sales/:id', permission: ['sales.cus_view_sale'], component: <SaleDetail/>},
    {path: '/sales/add', permission: ['sales.cus_add_sale'], component: <SaleForm/>},
    {path: '/sales/edit/:id', permission: ['sales.cus_change_sale'], component: <SaleForm/>},
    {path: '/sales/delete/:id', permission: ['sales.cus_delete_sale'], component: <DeleteSaleForm/>},
    {path: '/sales/:id/payments', permission: ['sales.cus_view_sale'], component: <PaymentList/>},
    {path: '/sales/:saleId/payments/add', permission: ['sales.cus_view_sale'], component: <PaymentForm/>},
    {path: '/sales/:saleId/payments/edit/:id', permission: ['sales.cus_view_sale'], component: <PaymentForm/>},
    {path: '/sales/:saleId/payments/delete/:id', permission: ['sales.cus_view_sale'], component: <DeletePaymentForm/>},
    {path: '/sales/:id/expences', permission: ['sales.cus_view_sale'], component: <ExpenceList/>},
    {path: '/sales/:saleId/expences/add', permission: ['sales.cus_view_sale'], component: <ExpenceForm/>},
    {path: '/sales/:saleId/expences/edit/:id', permission: ['sales.cus_view_sale'], component: <ExpenceForm/>},
    {path: '/sales/:saleId/expences/delete/:id', permission: ['sales.cus_view_sale'], component: <DeleteExpenceForm/>}
]

export default saleRoutes