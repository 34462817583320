import api from '../api'

class CustomerService {
    getCustomers(params={}){
        return api.get('/api/customers/', {params});
    }

    getCustomer(id){
        return api.get('/api/customers/' + id + '/')
    }

    createCustomer(payload={}){
        return api.post('/api/customers/', payload)
    }

    updateCustomer(id, payload={}){
        return api.put('/api/customers/' + id + '/', payload)
    }

    deleteCustomer(id){
        return api.delete('/api/customers/' + id + '/')
    }
}

export default new CustomerService()