import CustomerForm from '../../components/customers/CustomerForm'
import CustomerList from '../../components/customers/CustomerList'
import DeleteCustomerForm from '../../components/customers/DeleteCustomerForm'
import CustomerDetail from '../../components/customers/CustomerDetail'


const countryRoutes = [
    {path: '/customers', permission: ['customers.cus_view_customer'], component: <CustomerList/>},
    {path: '/customers/:id', permission: ['customers.cus_view_customer'], component: <CustomerDetail/>},
    {path: '/customers/add', permission: ['customers.cus_add_customer'], component: <CustomerForm/>},
    {path: '/customers/edit/:id', permission: ['customers.cus_change_customer'], component: <CustomerForm/>},
    {path: '/customers/delete/:id', permission: ['customers.cus_delete_customer'], component: <DeleteCustomerForm/>}
]

export default countryRoutes