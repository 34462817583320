import CustomerSalesReport from '../../components/reports/CustomerSalesReport'
import ExecutiveSalesReport from '../../components/reports/ExcecutiveSalesReport'
import AccountStatusReport from '../../components/reports/AccountStatusReport'


const reportRoutes = [
    {path: '/reports/sales/account-status-report', permission: ['sales.cus_view_account_status_report'], component: <AccountStatusReport/>},
    {path: '/reports/sales/customer-sales-report', permission: ['sales.cus_view_customer_sales_report'], component: <CustomerSalesReport/>},
    {path: '/reports/sales/executive-sales-report', permission: ['sales.cus_view_executive_sales_report'], component: <ExecutiveSalesReport/>}
]

export default reportRoutes