import api from '../api'


class CurrencyService {
    getCurrencies(params={}){
        return api.get('/api/sales/currencies/', {params});
    }

    getCurrency(id){
        return api.get('/api/sales/currencies/' + id + '/')
    }

    createCurrency(payload={}){
        return api.post('/api/sales/currencies/', payload)
    }

    updateCurrency(id, payload={}){
        return api.put('/api/sales/currencies/' + id + '/', payload)
    }

    deleteCurrency(id){
        return api.delete('/api/sales/currencies/' + id + '/')
    }
}

export default new CurrencyService()