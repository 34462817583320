import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Home from '../components/Home';
import Login from '../components/auth/Login';
import Error from '../components/Error';

import userRoutes from './pages/userRoutes';
import roleRoutes from './pages/roleRoutes';
import departmentRoutes from './pages/departmentRoutes';
import countryRoutes from './pages/countryRoutes';
import customerRoutes from './pages/customerRoutes';
import paymentTermRoutes from './pages/paymentTermRoutes';
import saleRoutes from './pages/saleRoutes';
import currencyRoutes from './pages/currencyRoutes';
import reportRoutes from './pages/reportRoutes';
import sellerRoutes from './pages/sellerRoutes';


const Paths = () => {
    
    const sysRoutes = [].concat(
        userRoutes, roleRoutes, departmentRoutes, countryRoutes,
        customerRoutes, paymentTermRoutes, saleRoutes,
        currencyRoutes, reportRoutes, sellerRoutes
    )

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<PrivateRoute><Home/></PrivateRoute>}/>,
                {
                    sysRoutes.map((route) => {
                        return <Route path={route.path} element={route.permission ?  <PrivateRoute permission={route.permission}>{route.component}</PrivateRoute> : route.component }/>
                    })
                }
                <Route path='/login' element={<Login/>}/>
                <Route path='/forbidden' element={
                    <Error 
                        code='403' 
                        title='Prohibido' 
                        message='No tienes permiso para realizar esta acción.'
                    />
                }></Route>
                <Route path='/not-found' element={
                    <Error 
                        code='404' 
                        title='Página no encontrada' 
                        message='Lo sentimos no pudimos encontrar la página que buscabas.'
                    />
                }></Route>
                <Route path='/server-error' element={
                    <Error 
                        code='500' 
                        title='Error en el servidor' 
                        message='Lo sentimos se ha generado un error en el servidor, el administrador del sistema ya fue notificado.'
                    />
                }></Route>
                <Route path='*' element={<Navigate to='/not-found' />}></Route>
            </Routes>  
        </BrowserRouter>
    );
};


export default Paths;