import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AuthService from '../services/auth/auth.service';
import { hasPermissions } from '../utils/permissions';


const PrivateRoute = ({permissions=[], children}) => {
    const userPermissions = useSelector(state => state.Auth.permissions);
    const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
    const location = useLocation();

    if(permissions && !hasPermissions(permissions, userPermissions)) return <Navigate to='/forbidden'/>
    
    if(isAuthenticated) AuthService.verifyToken(localStorage.getItem('access'))
    
    const url = '/login?next_url=' + location.pathname + location.search;

    if(!isAuthenticated) return <Navigate to={url}/>

    return children
}

export default PrivateRoute;