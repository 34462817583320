const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">2011 - {new Date().getFullYear()} CAPSA Panama, S.A.</div>
                    <div className="col-md-6">
                        <div className="text-md-end footer-links d-none d-md-block">
                            <a href="mailto:alejandro.monroy@capsamex.com.mx">Soporte</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;