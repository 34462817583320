import CurrencyForm from '../../components/currencies/CurrencyForm'
import CurrencyList from '../../components/currencies/CurrencyList'
import DeleteCurrencyForm from '../../components/currencies/DeleteCurrencyForm'
import CurrencyDetail from '../../components/currencies/CurrencyDetail'


const currencyRoutes = [
    {path: '/currencies', permission: ['sales.cus_view_currency'], component: <CurrencyList/>},
    {path: '/currencies/:id', permission: ['sales.cus_view_currency'], component: <CurrencyDetail/>},
    {path: '/currencies/add', permission: ['sales.cus_add_currency'], component: <CurrencyForm/>},
    {path: '/currencies/edit/:id', permission: ['sales.cus_change_currency'], component: <CurrencyForm/>},
    {path: '/currencies/delete/:id', permission: ['sales.cus_delete_currency'], component: <DeleteCurrencyForm/>}
]

export default currencyRoutes