import CountryForm from '../../components/countries/CountryForm'
import CountryList from '../../components/countries/CountryList'
import DeleteCountryForm from '../../components/countries/DeleteCountryForm'
import CountryDetail from '../../components/countries/CountryDetail'


const countryRoutes = [
    {path: '/countries', permission: ['countries.cus_view_country'], component: <CountryList/>},
    {path: '/countries/:id', permission: ['countries.cus_view_country'], component: <CountryDetail/>},
    {path: '/countries/add', permission: ['countries.cus_add_country'], component: <CountryForm/>},
    {path: '/countries/edit/:id', permission: ['countries.cus_change_country'], component: <CountryForm/>},
    {path: '/countries/delete/:id', permission: ['countries.cus_delete_country'], component: <DeleteCountryForm/>}
]

export default countryRoutes