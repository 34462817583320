import { useSelector } from 'react-redux';
import { useRef, useState, useEffect } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';

import { hasPermissions } from '../../utils/permissions';
import CurrencyService from '../../services/currencies/currency.service';
import { checkSuccessMessage } from '../../utils/messages';
import Pagination from '../layout/components/Pagination';
import Actions from '../layout/components/Actions';
import Layout from '../layout/Layout'
import CardMenu from '../layout/components/CardMenu'
import Modal from '../layout/components/Modal';


const CurrencyList = () => {
    const permissions = useSelector(state => state.Auth.permissions)
    const modalRef = useRef()
    const location = useLocation()
    const currentPath = location.pathname + location.search;
    const [loadingData, setLoadingData] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [pagination, setPagination] = useState({previous: null, next: null})

    const filters = {
        q: searchParams.get('q'),
        page: searchParams.get('page') || 1,
    }

    const [currencies, setCurrencies] = useState([])
    const loadCurrencies = async () => {
        setLoadingData(true);
        CurrencyService.getCurrencies(filters)
        .then((response) => {
            setCurrencies(response.data.results);
            setPagination({
                previous: response.data.previous,
                next: response.data.next 
            })
            setLoadingData(false);
        })
    }

    useEffect(() => {
        loadCurrencies();
        checkSuccessMessage();
    }, []);

    return <Layout title='Monedas'>
        <CardMenu>
            <div className="mb-3 text-end">
                <button className='btn btn-light me-1' onClick={() => {modalRef.current.toggleModal()}}>Filtros</button>
                {hasPermissions(['sales.cus_add_currency'], permissions) && 
                    <Link 
                    to={'/currencies/add' + '?prev_url=' + currentPath}
                    className='btn btn-primary'>
                        Nueva moneda
                    </Link>
                }
            </div>
            <div className='table-responsive table-grid'>
                <table className='table-centered table table-hover'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Código</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loadingData ?
                                currencies.length ? 
                                    currencies.map((obj, i) => {
                                        return <tr key={i}>
                                            <td>{obj.name}</td>
                                            <td>{obj.code}</td>
                                            <td>
                                                <Actions
                                                actions={[
                                                    {'label': 'Detalles', 'link': 'currencies/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['sales.cus_view_currency'], permissions)},
                                                    {'label': 'Editar', 'link': 'currencies/edit/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['sales.cus_change_currency'], permissions)},
                                                    {'label': 'Eliminar', 'link': 'currencies/delete/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['sales.cus_delete_currency'], permissions)}
                                                ]}></Actions>
                                            </td>
                                        </tr>
                                })
                                :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                            :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                        }
                    </tbody>
                </table>
                <Pagination previous={pagination['previous']} next={pagination['next']}></Pagination>
            </div>
        </CardMenu>

        <Modal title='Filtros' ref={modalRef}>
            <form method='GET'>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Palabra clave</label>
                    <input type="text" className='form-control w-100' name='q' defaultValue={filters['q']} />
                </div>
                <div className="mb-3 text-end">
                    <input type="submit" className='btn btn-primary' value='Buscar' />
                </div>
            </form>
        </Modal>

    </Layout>
}

export default CurrencyList